import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText } from '@prismicio/react'

// components
import Layout from '../components/layout'
import Seo from '../components/seo'

export const query = graphql`
  query page404 {
    prismicPage(uid: { eq: "404" }) {
      ...PrismicPageFragment
    }
  }
`

const NotFoundPage = ({ data }) => {
  const pageData = data.prismicPage.data
  return (
    <Layout>
      <Seo title={pageData.meta_title ? pageData.meta_title : pageData.title} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      <div className='text-center leading-tight md:text-6xl text-5xl z-10 relative flex flex-col justify-center items-center py-20'>
        <div className='tracking-tight lg:w-2/3'>
          <PrismicRichText field={pageData.page_title.richText} />
        </div>
        <div className='mt-3 text-2xl md:mt-5 lg:w-2/3'>
          <PrismicRichText field={pageData.page_text.richText} />
        </div>
        {pageData.page_button_link && pageData.page_button_text && (
          <a className='button mx-auto mt-4' href={pageData.page_button_link.url}>
            {pageData.page_button_text}
          </a>
        )}
      </div>
    </Layout>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)
